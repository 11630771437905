import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import commaNumber from "comma-number";

const Courses = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/courses');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">کۆرس</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">کۆرس</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">کۆرسەکێ نوی</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">کۆرسێکی نوی</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/auth/courses`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('هاتە زێدەکرن');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">بابەت</label>
                                                            <input type="text" className="form-control" name="subject" placeholder="ناڤێ بابەتی..." required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">پێزانین</label>
                                                            <input type="text" className="form-control" name="description" placeholder=" پێزانین لسەەر کۆرسی..." required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناڤێ مامۆستای</label>
                                                            <input type="text" className="form-control" name="teacher" placeholder="ناڤێ مامۆستای ..." required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">جهـ</label>
                                                            <input type="text" className="form-control" name="school" placeholder="قوتابخانە/جهێ کۆرسی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="cover" defaultValue={selectedFile} required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">نرخ</label>
                                                            <input type="number" className="form-control" name="price" placeholder="0.00" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">زێدەکرن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>بابەت</th>
                                        <th>مامۆستا</th>
                                        <th>جهـ</th>
                                        <th>نرخ</th>
                                        <th>ڕێککەفت</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.subject}</td>
                                                <td>{item.teacher}</td>
                                                <td>{item.school}</td>
                                                <td>{commaNumber(item.price)} د.ع</td>
                                                <td>{new Date(item.created).toLocaleDateString()}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                        Swal.fire({
                                                            title: 'هشیاربە !',
                                                            text: "پشتی داتا هاتە ژێربن تو نەشێی زڤرینی",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'ژێبرن',
                                                            cancelButtonText: 'داخستن'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/auth/courses/${item.id}`).then(() => {
                                                                    getData();
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                        <icon.Trash size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">دەستکاریکرن</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/auth/courses/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('نویکرایەوە');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">بابەت</label>
                                                                        <input type="text" className="form-control" name="subject" defaultValue={item.subject} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">پێزانین</label>
                                                                        <input type="text" className="form-control" name="description" defaultValue={item.description} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">ناڤێ مامۆستای</label>
                                                                        <input type="text" className="form-control" name="teacher" defaultValue={item.teacher} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">جهـ</label>
                                                                        <input type="text" className="form-control" name="school" defaultValue={item.school} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">وێنە</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="cover" defaultValue={selectedFile ? selectedFile : item.cover} required />
                                                                        <small className="text-muted">{item.cover}</small>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">نرخ</label>
                                                                        <input type="number" className="form-control" name="price" defaultValue={item.price} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary">نویکرن</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Courses;