import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from "react-select";
import Pagination from "../Pagination";

const Quiz = ({ icon }) => {

    const gradeList = [
        { value: 1, grade: 9, edu_type: 3, label: 'پۆلا نەهێ یا بنەڕەت' },
        { value: 2, grade: 10, edu_type: 1, label: 'پۆلا دەهێ یا زانستی' },
        { value: 3, grade: 11, edu_type: 1, label: 'پۆلا یازدێ یا زانستی' },
        { value: 4, grade: 12, edu_type: 1, label: 'پۆلا دوازدێ یا زانستی' },
        { value: 5, grade: 10, edu_type: 2, label: 'پۆلا دەهێ یا وێژەیی' },
        { value: 6, grade: 11, edu_type: 2, label: 'پۆلا یازدێ یا وێژەیی' },
        { value: 7, grade: 12, edu_type: 2, label: 'پۆلا دوازدێ یا وێژەیی' },
        { value: 8, grade: 10, edu_type: 0, label: 'پۆلا دەهێ' },
        { value: 9, grade: 11, edu_type: 0, label: 'پۆلا یازدێ' },
        { value: 10, grade: 12, edu_type: 0, label: 'پۆلا دوازدێ' },
    ]

    const gradeFilter = [
        { value: 12, label: 'پۆلا دوازدێ' },
        { value: 11, label: 'پۆلا یازدێ' },
        { value: 10, label: 'پۆلا دەهێ' },
        { value: 9, label: 'پۆلا نەهێ یا بنەڕەت' },
    ]

    const eduType = [
        { value: 1, label: 'زانستی' },
        { value: 2, label: 'وێژەیی' },
        { value: 3, label: 'بنەڕەت' },
        { value: 0, label: 'زانستی و وێژەیی' }
    ]

    const [allSubject, setAllSubject] = useState([]);
    const getallSubject = async () => {
        const { data } = await axios.get(`/auth/subject`);
        setAllSubject(data.map((item) => {
            return {
                value: item.id,
                label: item.subject
            };
        }))
    }

    const [edu_type, setEdu_type] = useState(null);
    const [grade, setGrade] = useState(null);

    const [subject, setsubject] = useState([]);
    const getSubject = async (edu_type) => {
        const { data } = await axios.get(`/auth/subject/${edu_type}`);
        setsubject(data.map((item) => {
            return {
                value: item.id,
                label: item.subject
            };
        }))
    }

    const [question_id, setQuestion_id] = useState(null)
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState([]);

    const fetchData = async (page) => {
        const { data } = await axios.get(`/auth/questionv2`, { params: { page: page, search: search } });
        setData(data.data);
        setTotalPages(Math.ceil(data.totalPages / 10));
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAdvanceSearch = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        setSearch(data);
        await fetchData(currentPage); // Wait for data to be updated
    }

    useEffect(() => {
        getallSubject()
        fetchData(currentPage);
    }, [currentPage, search]);

    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [selectQuestion, setSelectQuestion] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectQuestion(data);
    };

    const [option_a, setOption_a] = useState(null);
    const upload_a = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setOption_a(data);
    };

    const [option_b, setOption_b] = useState(null);
    const upload_b = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setOption_b(data);
    };

    const [option_c, setOption_c] = useState(null);
    const upload_c = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setOption_c(data);
    };

    const [option_d, setOption_d] = useState(null);
    const upload_d = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setOption_d(data);
    };


    function isImageUrl(str) {
        if (typeof str !== 'string') return false;
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(str);
    }

    const [answer, setAnswer] = useState([])

    const handleChange = (event) => {
        setAnswer({ ...answer, correct: event.target.value });
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">تاقیکرنا ئەلیکترۆنی</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تاقیکرنا ئەلیکترۆنی</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">زێدەکرن</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">پرسیارەکا نوی</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/auth/question`, data).then((res) => {
                                                        if (isNaN(res.data)) {
                                                            toast.error('تەخەلەت کر دوبارە چێکە !!!')
                                                            setTimeout(() => {
                                                                window.location.reload();
                                                            }, 2000);
                                                        } else {
                                                            setQuestion_id(res.data)
                                                        }
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">ئاستێ خاندن</label>
                                                            <Select options={gradeList} onChange={(e) => {
                                                                setEdu_type(e.edu_type);
                                                                setGrade(e.grade);
                                                                getSubject(e.edu_type);
                                                            }} placeholder=" ئاستێ خاندن دیاربکە" required />
                                                            <input type="hidden" name="edu_type" defaultValue={edu_type} />
                                                            <input type="hidden" name="grade" defaultValue={grade} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">بابەت</label>
                                                            <Select options={subject} name="subject_id" placeholder="بابەت دیاربکە" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">بەندێ بابەتی</label>
                                                            <select className="form-control" name="unit" required>
                                                                <option value={0}>گشتی</option>
                                                                <option value={1}>بەندێ (1)</option>
                                                                <option value={2}>بەندێ (2)</option>
                                                                <option value={3}>بەندێ (3)</option>
                                                                <option value={4}>بەندێ (4)</option>
                                                                <option value={5}>بەندێ (5)</option>
                                                                <option value={6}>بەندێ (6)</option>
                                                                <option value={7}>بەندێ (7)</option>
                                                                <option value={8}>بەندێ (8)</option>
                                                                <option value={9}>بەندێ (9)</option>
                                                                <option value={10}>بەندێ (10)</option>
                                                                <option value={11}>بەندێ (11)</option>
                                                                <option value={12}>بەندێ (12)</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">جورێ پرسیار</label>
                                                            <select className="form-control" name="question_type" required>
                                                                <option>ویزاری</option>
                                                                <option>ئەزموونی گشتی</option>
                                                                <option>دەرەکی</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">خوول</label>
                                                            <select className="form-control" name="question_round" required>
                                                                <option value={1}>خوولی یەکەم</option>
                                                                <option value={2}>خوولی دووەم</option>
                                                                <option value={0}>تەمهیدی</option>
                                                                <option value={3}>بەندەکان</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label"> دیالێکت دیاربکە</label>
                                                            <select className="form-control" name="edu_lang" required>
                                                                <option>بادینی</option>
                                                                <option>سۆرانی</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label"> پرسیار ب تێکست</label>
                                                            <textarea className="form-control" onChange={(e) => { setSelectQuestion(e.target.value) }} placeholder=" پرسیار بنڤیسە ..." />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ئان ژی پرسیار ب وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} />
                                                            <input type="hidden" name="question" defaultValue={selectQuestion} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label"> سال</label>
                                                            <input type="text" className="form-control" name="season" placeholder=" 2023-2023" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label"> تێبینی</label>
                                                            <input type="text" className="form-control" name="note" placeholder=" تێبینی هەکە هەبوو بنڤیسە ..." />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary" data-bs-target="#answers" data-bs-toggle="modal" data-bs-dismiss="modal">پێنگاڤا دیڤدا</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="answers" tabIndex={-1} aria-labelledby="answers" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">دانانا وەڵام بۆ پرسیارێ تۆمارکری</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    if (option_a && option_b && option_c && option_d) {
                                                        axios.post(`/auth/answer`, data).then((res) => {
                                                            if (isNaN(res.data)) {
                                                                toast.error('تەخەلەت کر دوبارە چێکە !!!')
                                                                setTimeout(() => {
                                                                    window.location.reload();
                                                                }, 2000);
                                                            } else {
                                                                toast.success('هاتە زێدەکرن');
                                                                setTimeout(() => {
                                                                    window.location.reload();
                                                                }, 2000);
                                                            }
                                                        })
                                                    } else {
                                                        toast.error("پێویستە هەموو خانەکان پڕ بکەیت")
                                                        return;
                                                    }
                                                }} >
                                                    <div className="modal-body">
                                                        <input type="hidden" name="quiz_question_id" defaultValue={question_id} />
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <div className="mb-3">
                                                                    <label className="form-label">وەڵام (A)</label>
                                                                    <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." onChange={(e) => { setOption_a(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label className="form-label">ئان ژی وەڵام (A) ب وێنە</label>
                                                                    <input type="file" className="form-control" onChange={upload_a} />
                                                                    <input type="hidden" name="option_a" defaultValue={option_a} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className="mb-3">
                                                                    <label className="form-label">وەڵام (B)</label>
                                                                    <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." onChange={(e) => { setOption_b(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label className="form-label">ئان ژی وەڵام (B) ب وێنە</label>
                                                                    <input type="file" className="form-control" onChange={upload_b} />
                                                                    <input type="hidden" name="option_b" defaultValue={option_b} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className="mb-3">
                                                                    <label className="form-label">وەڵام (C)</label>
                                                                    <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." onChange={(e) => { setOption_c(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label className="form-label">ئان ژی وەڵام (C) ب وێنە</label>
                                                                    <input type="file" className="form-control" onChange={upload_c} />
                                                                    <input type="hidden" name="option_c" defaultValue={option_c} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className="mb-3">
                                                                    <label className="form-label">وەڵام (D)</label>
                                                                    <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." onChange={(e) => { setOption_d(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label className="form-label">ئان ژی وەڵام (D) ب وێنە</label>
                                                                    <input type="file" className="form-control" onChange={upload_d} />
                                                                    <input type="hidden" name="option_d" defaultValue={option_d} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">وەڵام ڕاست ؟</label>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="correct" id="inlineRadio1" defaultValue="a" defaultChecked />
                                                                <label className="form-check-label" htmlFor="inlineRadio1">(A)</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="correct" id="inlineRadio2" defaultValue="b" />
                                                                <label className="form-check-label" htmlFor="inlineRadio2">(B)</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="correct" id="inlineRadio3" defaultValue="c" />
                                                                <label className="form-check-label" htmlFor="inlineRadio3">(C)</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="correct" id="inlineRadio4" defaultValue="d" />
                                                                <label className="form-check-label" htmlFor="inlineRadio4">(D)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">زێدەکرن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleAdvanceSearch}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={gradeFilter} name="grade" placeholder="ئاستێ خاندن دیاربکە" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={eduType} name="edu_type" placeholder="جورێ خاندن" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={allSubject} name="subject_id" placeholder="بابەت دیاربکە" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={
                                                [
                                                    { value: 'ویزاری', label: 'ویزاری' },
                                                    { value: 'ئەزموونی گشتی', label: 'ئەزموونی گشتی' },
                                                    { value: 'دەرەکی', label: 'دەرەکی' }
                                                ]
                                            } name="question_type" placeholder="جورێ پرسیار" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={
                                                [
                                                    { value: 1, label: 'خوولی یەکەم' },
                                                    { value: 2, label: 'خوولی دووەم' },
                                                    { value: 0, label: 'تەمهیدی' }
                                                ]
                                            } name="question_round" placeholder="خوول" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <Select options={
                                                [
                                                    { value: 'بادینی', label: 'بادینی' },
                                                    { value: 'سۆرانی', label: 'سۆرانی' }
                                                ]
                                            } name="edu_lang" placeholder="دیالێکت" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="season" placeholder="2023-2023" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary">گەڕان</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>پرسیار</th>
                                        <th>بابەت</th>
                                        <th>پۆل</th>
                                        <th>ئاست</th>
                                        <th>جۆر</th>
                                        <th>بەند</th>
                                        <th>ساڵ</th>
                                        <th>خوول</th>
                                        <th>دیالێکت</th>
                                        <th>تێبینی</th>
                                        <th>سڕینەوە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {isImageUrl(item.question) ?
                                                        <img src={`https://zankobadinan.com/${item.question}`} alt="Question" /> :
                                                        item.question
                                                    }
                                                </td>
                                                <td>{item.subject}</td>
                                                <td>{item.grade}</td>
                                                <td>{item.edu_str}</td>
                                                <td>{item.question_type}</td>
                                                <td>{item.unit == 0 ? 'گشتی' : item.unit}</td>
                                                <td>{item.season}</td>
                                                <td>{item.question_round == 3 ? 'بەندەکان' : item.question_round}</td>
                                                <td>{item.edu_lang}</td>
                                                <td>{item.note}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#new${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                        Swal.fire({
                                                            title: 'هشیاربە !',
                                                            text: "پشتی داتا هاتە ژێربن تو نەشێی زڤرینی",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'ژێبرن',
                                                            cancelButtonText: 'داخستن'
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/auth/question/${item.id}`).then(() => {
                                                                    fetchData(currentPage);
                                                                    toast.error('هاتە ژێبرن !');
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                        <icon.Trash size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`new${item.id}`} tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel"> دەستکاریکرن</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/auth/question/${item.id}`, data).then((res) => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error('تەخەلەت کر دوبارە چێکە !!!')
                                                                        setTimeout(() => {
                                                                            window.location.reload();
                                                                        }, 2000);
                                                                    } else {
                                                                        setQuestion_id(res.data)
                                                                        axios.get(`/auth/answer/${item.id}`).then((res) => {
                                                                            setAnswer(res.data[0])
                                                                        })
                                                                    }
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">ئاستێ خاندن</label>
                                                                        <Select options={gradeList} onChange={(e) => {
                                                                            $(`#edu_type_${item.id}`).val(e.edu_type);
                                                                            $(`#grade_${item.id}`).val(e.grade);
                                                                            getSubject(e.edu_type)
                                                                        }} placeholder=" ئاستێ خاندن دیاربکە" defaultValue={{ value: item.edu_type, label: item.edu_str }} required />
                                                                    </div>
                                                                    <div style={{ display: 'none' }}>
                                                                        <input type="text" name="edu_type" id={`edu_type_${item.id}`} defaultValue={item.edu_type} />
                                                                        <input type="text" name="grade" id={`grade_${item.id}`} defaultValue={item.grade} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">بابەت</label>
                                                                        <Select options={subject} name="subject_id" placeholder="بابەت دیاربکە" defaultValue={{ value: item.subject_id, label: item.subject }} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">بەندێ بابەتی</label>
                                                                        <select className="form-control" name="unit" required defaultValue={item.unit}  >
                                                                            <option value={0}>گشتی</option>
                                                                            <option value={1}>بەندێ (1)</option>
                                                                            <option value={2}>بەندێ (2)</option>
                                                                            <option value={3}>بەندێ (3)</option>
                                                                            <option value={4}>بەندێ (4)</option>
                                                                            <option value={5}>بەندێ (5)</option>
                                                                            <option value={6}>بەندێ (6)</option>
                                                                            <option value={7}>بەندێ (7)</option>
                                                                            <option value={8}>بەندێ (8)</option>
                                                                            <option value={9}>بەندێ (9)</option>
                                                                            <option value={10}>بەندێ (10)</option>
                                                                            <option value={11}>بەندێ (11)</option>
                                                                            <option value={12}>بەندێ (12)</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">جورێ پرسیار</label>
                                                                        <select className="form-control" name="question_type" required
                                                                            defaultValue={item.question_type}
                                                                        >
                                                                            <option>ویزاری</option>
                                                                            <option>ئەزموونی گشتی</option>
                                                                            <option>دەرەکی</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">خوول</label>
                                                                        <select className="form-control" name="question_round" required
                                                                            defaultValue={item.question_round}
                                                                        >
                                                                            <option value={1}>خوولی یەکەم</option>
                                                                            <option value={2}>خوولی دووەم</option>
                                                                            <option value={0}>تەمهیدی</option>
                                                                            <option value={3}>بەندەکان</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> دیالێکت دیاربکە</label>
                                                                        <select className="form-control" name="edu_lang" required
                                                                            defaultValue={item.edu_lang}
                                                                        >
                                                                            <option>بادینی</option>
                                                                            <option>سۆرانی</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> پرسیار ب تێکست</label>
                                                                        <textarea className="form-control" onChange={(e) => { setSelectQuestion(e.target.value) }} defaultValue={item.question} placeholder=" پرسیار بنڤیسە ..." />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">ئان ژی پرسیار ب وێنە</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} />
                                                                        <input type="hidden" name="question" defaultValue={selectQuestion ? selectQuestion : item.question} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> سال</label>
                                                                        <input type="text" className="form-control" name="season" placeholder=" 2023-2023" defaultValue={item.season} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label"> تێبینی</label>
                                                                        <input type="text" className="form-control" name="note" placeholder=" تێبینی هەکە هەبوو بنڤیسە ..." defaultValue={item.note} />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary" data-bs-target={`#answers${item.id}`} data-bs-toggle="modal" data-bs-dismiss="modal">پێنگاڤا دیڤدا</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`answers${item.id}`} tabIndex={-1} aria-labelledby="answers" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">دانانا وەڵام بۆ پرسیارێ تۆمارکری</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/auth/answer/${question_id}`, data).then((res) => {
                                                                    toast.success('هاتە نویکرن');
                                                                    setTimeout(() => {
                                                                        window.location.reload();
                                                                    }, 2000);
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <input type="hidden" name="quiz_question_id" defaultValue={question_id} />
                                                                    <div className="row">
                                                                        <div className="col-md-7">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وەڵام (A)</label>
                                                                                <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." defaultValue={answer.option_a} onChange={(e) => { setOption_a(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ئان ژی وەڵام (A) ب وێنە</label>
                                                                                <input type="file" className="form-control" onChange={upload_a} />
                                                                                <input type="hidden" name="option_a" defaultValue={option_a ? option_a : answer.option_a} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وەڵام (B)</label>
                                                                                <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." defaultValue={answer.option_b} onChange={(e) => { setOption_b(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ئان ژی وەڵام (B) ب وێنە</label>
                                                                                <input type="file" className="form-control" onChange={upload_b} />
                                                                                <input type="hidden" name="option_b" defaultValue={option_b ? option_b : answer.option_b} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وەڵام (C)</label>
                                                                                <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." defaultValue={answer.option_c} onChange={(e) => { setOption_c(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ئان ژی وەڵام (C) ب وێنە</label>
                                                                                <input type="file" className="form-control" onChange={upload_c} />
                                                                                <input type="hidden" name="option_c" defaultValue={option_c ? option_c : answer.option_c} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وەڵام (D)</label>
                                                                                <input className="form-control" placeholder=" وەڵامەکێ بنڤیسە ..." defaultValue={answer.option_d} onChange={(e) => { setOption_d(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ئان ژی وەڵام (D) ب وێنە</label>
                                                                                <input type="file" className="form-control" onChange={upload_d} />
                                                                                <input type="hidden" name="option_d" defaultValue={option_d ? option_d : answer.option_d} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label className="form-label">وەڵام ڕاست ؟</label>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="correct"
                                                                                id="inlineRadio1"
                                                                                value="a"
                                                                                checked={answer.correct === 'a'}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="inlineRadio1">(A)</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="correct"
                                                                                id="inlineRadio2"
                                                                                value="b"
                                                                                checked={answer.correct === 'b'}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="inlineRadio2">(B)</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="correct"
                                                                                id="inlineRadio3"
                                                                                value="c"
                                                                                checked={answer.correct === 'c'}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="inlineRadio3">(C)</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="correct"
                                                                                id="inlineRadio4"
                                                                                value="d"
                                                                                checked={answer.correct === 'd'}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="inlineRadio4">(D)</label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">نویکرن</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Quiz;