import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from './Login';
import Dashboard from './Pages/Dashboard';
import Courses from './Pages/Courses';
import Posts from './Pages/Posts';
import Ads from './Pages/Ads';
import Categories from './Pages/Categories';
import General from './Pages/General';
import Intro from './Pages/Intro';
import Social from './Pages/Social';
import Staff from './Pages/Staff';
import Students from './Pages/Students';
import Accounts from './Pages/Accounts';
import Submition from './Pages/Submition';
import Quiz from './Pages/Quiz';
import Quizer from './Pages/Quizer';

const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Dashboard icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/courses",
        element: <APP><Courses icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/posts",
        element: <APP><Posts icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/ads",
        element: <APP><Ads icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/categories",
        element: <APP><Categories icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/general",
        element: <APP><General icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/intro",
        element: <APP><Intro icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/social",
        element: <APP><Social icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/staff",
        element: <APP><Staff icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/accounts",
        element: <APP><Accounts icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/students",
        element: <APP><Students icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/submition",
        element: <APP><Submition icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/quiz",
        element: <APP><Quiz icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/quizer",
        element: <APP><Quizer icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },


]
);

export default Routes;