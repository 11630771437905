import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from "react-select";

const Posts = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/posts');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [cat, setCat] = useState([]);
    const getCat = async () => {
        const { data } = await axios.get('/auth/categories');
        setCat(data.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        }))
    }

    const [info, setInfo] = useState([])
    useEffect(() => {
        axios.get("/auth/auth").then((res) => {
            setInfo(res.data)
        });
        getData();
        getCat();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    const [selectedFile2, setSelectedFile2] = useState(null);
    const uploadChange2 = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile2(data);
    };

    const [attachment, setAttachment] = useState([]);
    const getAttachments = async (post_id) => {
        const { data } = await axios.get(`/auth/attachment/${post_id}`)
        setAttachment(data)
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">پێزانین</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">پێزانین</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">پۆستەکێ نوی</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">پۆستەکێ نوی</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/auth/post`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('هاتە زێدەکرن');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">تایتل</label>
                                                            <input type="text" className="form-control" name="title" placeholder=" تایتل..." required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناڤەڕۆک</label>
                                                            <textarea type="text" className="form-control" name="content" col={6} placeholder=" ناڤەڕۆک..." required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">پشک دیار بکە</label>
                                                            <Select options={cat} name="category_id" placeholder="پشک دیارکە ...." />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="cover" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">زێدەکرن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>تایتل</th>
                                        <th>پشک</th>
                                        <th>بەکارهێنەر</th>
                                        <th>ڕۆل</th>
                                        <th>رێککەفت</th>
                                        <th>بژاردە</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.category}</td>
                                                <td>{item.username}</td>
                                                <td>{item.role === 0 ? 'ئەندام' : 'ئەدمین'}</td>
                                                <td>{new Date(item.created).toLocaleDateString()}</td>
                                                <td>{info.role === 1 ?
                                                    <>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'هشیاربە !',
                                                                text: "پشتی داتا هاتە ژێربن تو نەشێی زڤرینی",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'ژێبرن',
                                                                cancelButtonText: 'داخستن'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/auth/post/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-info mx-1" data-bs-toggle="modal" data-bs-target={`#att${item.id}`} onClick={() => { getAttachments(item.id) }}  >
                                                            <icon.Paperclip size="18" />
                                                        </button>

                                                    </> : info.account_id == item.account_id &&
                                                    <>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'هشیاربە !',
                                                                text: "پشتی داتا هاتە ژێربن تو نەشێی زڤرینی",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'ژێبرن',
                                                                cancelButtonText: 'داخستن'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/auth/post/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-info mx-1" data-bs-toggle="modal" data-bs-target={`#att${item.id}`} onClick={() => { getAttachments(item.id) }}  >
                                                            <icon.Paperclip size="18" />
                                                        </button>
                                                    </>
                                                }
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">دەستکاریکرن</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/auth/post/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('نویکرایەوە');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">تایتل</label>
                                                                        <input type="text" className="form-control" name="title" defaultValue={item.title} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">ناڤەڕۆک</label>
                                                                        <textarea type="text" className="form-control" name="content" col={6} defaultValue={item.content} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">پشک دیار بکە</label>
                                                                        <Select options={cat} name="category_id" defaultValue={{ value: item.category_id, label: item.category }} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">وێنە</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                                        <input type="hidden" name="cover" defaultValue={selectedFile ? selectedFile : item.cover} required />
                                                                        <small className="text-muted">{item.cover}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                    <button type="submit" className="btn btn-primary">نویکرن</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`att${item.id}`} tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">بارکرنا هاوپێچ</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">هاوپێچ</label>
                                                                            <input type="file" className="form-control" onChange={uploadChange2} />
                                                                            <input type="hidden" id="attachment" defaultValue={selectedFile2} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">تایتل</label>
                                                                            <input type="text" className="form-control" id="attachment_title" placeholder=" تایتل..." required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <button type="submit" className="btn btn-primary" onClick={() => {
                                                                            axios.post('/auth/attachment', { attachment: $('#attachment').val(), attachment_title: $('#attachment_title').val(), post_id: item.id }).then(() => {
                                                                                getAttachments(item.id)
                                                                            })
                                                                        }} >بارکرن</button>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <table className="table table-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>ناڤونیشان</th>
                                                                                    <th>هاوپێچ</th>
                                                                                    <th>ژێبرن</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {attachment.map((obj, i) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{i + 1}</td>
                                                                                            <td>{obj.attachment_title}</td>
                                                                                            <td>{obj.attachment}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                                                                    Swal.fire({
                                                                                                        title: 'هشیاربە !',
                                                                                                        text: "پشتی داتا هاتە ژێربن تو نەشێی زڤرینی",
                                                                                                        icon: 'warning',
                                                                                                        showCancelButton: true,
                                                                                                        confirmButtonColor: '#3085d6',
                                                                                                        cancelButtonColor: '#d33',
                                                                                                        confirmButtonText: 'ژێبرن',
                                                                                                        cancelButtonText: 'داخستن'
                                                                                                    }).then((result) => {
                                                                                                        if (result.isConfirmed) {
                                                                                                            axios.delete(`/auth/attachment/${obj.id}?fname=${obj.attachment}`).then(() => {
                                                                                                                getAttachments(item.id)
                                                                                                            })
                                                                                                        }
                                                                                                    })
                                                                                                }}>
                                                                                                    <icon.Trash size="18" />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default Posts;