import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";

const Intro = () => {

    const [data, setData] = useState([]);
    const [inId, setIntId] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/intro');
        setData(data);
        setIntId(data.id)
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [selectedFile2, setSelectedFile2] = useState(null);
    const uploadChange2 = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile2(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">سلاید</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">دەستکاریکرنا پشکا ئێکی یا ویب سایتی</h6>
                                </div>
                                <div className="col-md-12">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.put(`/auth/intro/${inId}`, data).then(() => {
                                            toast.success('هاتە گهوڕین');
                                            setTimeout(() => {
                                                window.location.reload(true)
                                            }, 1000);
                                        })
                                    }} >
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">سەرنڤیس</label>
                                                    <input type="text" className="form-control" name="caption" defaultValue={data.caption} placeholder="ژ دوو سێ پەیڤان چێکە" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">تایتل</label>
                                                    <input type="text" className="form-control" name="title" defaultValue={data.title} placeholder="تایتل ...." required />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label className="form-label">لینک</label>
                                                    <input type="text" className="form-control" name="linkto" defaultValue={data.linkto} placeholder="contact" required />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">ناڤەڕۆک</label>
                                                    <textarea type="text" className="form-control" name="content" col={8} defaultValue={data.content} placeholder="ناڤەڕۆک ...." required />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">وێنە</label>
                                                    <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                    <input type="hidden" name="min_image" defaultValue={selectedFile ? selectedFile : data.min_image} />
                                                    <small className="text-muted">{data.min_image}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">وێنە</label>
                                                    <input type="file" className="form-control" onChange={uploadChange2} accept="application/pdf, image/*" />
                                                    <input type="hidden" name="max_image" defaultValue={selectedFile2 ? selectedFile2 : data.max_image} />
                                                    <small className="text-muted">{data.max_image}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">لینک</label>
                                                    <input type="text" className="form-control" name="alt_image" defaultValue={data.alt_image} placeholder="لینک ...." required />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">گهوڕین</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Intro;