/* eslint-disable */
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";

const Navbar = ({ icon }) => {
    const navigate = useNavigate();
    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data === 'done') {
                navigate('/login')
            }
        })
    }
    return (
        <nav className="navbar">
            <a href="/" className="sidebar-toggler" onClick={(e) => {
                e.preventDefault();
                $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('body').toggleClass('sidebar-folded');
                } else if (window.matchMedia('(max-width: 991px)').matches) {
                    $('body').toggleClass('sidebar-open');
                }
            }}>
                <icon.Menu size={20} />
            </a>
            <div className="navbar-content">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="/" className="text-dark ms-0" onClick={() => { logout(); return false; }}>
                            <icon.Power />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;