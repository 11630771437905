import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

const Sidebar = ({ icon }) => {
    const navigate = useNavigate();
    const [info, setInfo] = useState([])
    useEffect(() => {
        axios.get("/auth/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            } else {
                setInfo(res.data)
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    زانکۆ<span> بادینان</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                {info.role !== 0 ?
                    <ul className="nav">
                        <li className="nav-item nav-category">سەرەکی </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <icon.TrendingUp className="link-icon" />
                                <span className="link-title">سەرژمێری</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/courses" className="nav-link">
                                <icon.Edit className="link-icon" />
                                <span className="link-title">کۆرس</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/submition" className="nav-link">
                                <icon.Edit className="link-icon" />
                                <span className="link-title">تومارا فورمی</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/posts" className="nav-link">
                                <icon.Users className="link-icon" />
                                <span className="link-title">پێزانین</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/categories" className="nav-link">
                                <icon.Layers className="link-icon" />
                                <span className="link-title">پشک</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/ads" className="nav-link">
                                <icon.Twitch className="link-icon" />
                                <span className="link-title">ڕاگەهاندن</span>
                            </Link>
                        </li>
                        <li className="nav-item nav-category">رێکخستن </li>
                        <li className="nav-item">
                            <Link to="/social" className="nav-link">
                                <icon.Share2 className="link-icon" />
                                <span className="link-title">سۆشیال</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/staff" className="nav-link">
                                <icon.Users className="link-icon" />
                                <span className="link-title">ستاف</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/intro" className="nav-link">
                                <icon.Image className="link-icon" />
                                <span className="link-title">سلاید</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/accounts" className="nav-link">
                                <icon.UserCheck className="link-icon" />
                                <span className="link-title">هەژمار</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/general" className="nav-link">
                                <icon.Settings className="link-icon" />
                                <span className="link-title">رێکخستن</span>
                            </Link>
                        </li>
                        <li className="nav-item nav-category">تومار </li>
                        <li className="nav-item">
                            <Link to="/students" className="nav-link">
                                <icon.FileText className="link-icon" />
                                <span className="link-title">قوتابیان</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/quiz" className="nav-link">
                                <icon.HelpCircle className="link-icon" />
                                <span className="link-title">تاقیکرن</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/quizer" className="nav-link">
                                <icon.Users className="link-icon" />
                                <span className="link-title">بەشداربووان</span>
                            </Link>
                        </li>
                    </ul> :
                    <ul className="nav">
                        <li className="nav-item nav-category">سەرەکی </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <icon.TrendingUp className="link-icon" />
                                <span className="link-title">سەرژمێری</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/posts" className="nav-link">
                                <icon.Users className="link-icon" />
                                <span className="link-title">پێزانین</span>
                            </Link>
                        </li>
                    </ul>
                }

            </div>
        </nav>
    );
}

export default Sidebar;