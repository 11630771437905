import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import commaNumber from "comma-number";

const Dashboard = ({ icon }) => {
    const [stats, setStats] = useState([]);
    useEffect(() => {
        axios.get('/auth/stats').then(res => {
            const students = res.data.students[0] ?? { total: 0 };
            const courses = res.data.courses[0] ?? { total: 0 };
            const posts = res.data.posts[0] ?? { total: 0 };
            const categories = res.data.categories[0] ?? { total: 0 };
            const staff = res.data.staff[0] ?? { total: 0 };
            const visitors = res.data.visitors[0] ?? { total: 0 };
            setStats(
                <div className="col-md-12">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> ژمارا سەردان</span>
                                            <h3 className="card-title mb-2">{visitors ? commaNumber(visitors.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> قوتابی</span>
                                            <h3 className="card-title mb-2">{students ? commaNumber(students.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Users /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> بابەت</span>
                                            <h3 className="card-title mb-2">{posts ? commaNumber(posts.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.FileText /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> کۆرس</span>
                                            <h3 className="card-title mb-2">{courses ? commaNumber(courses.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Twitch /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> پشک</span>
                                            <h3 className="card-title mb-2">{categories ? commaNumber(categories.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.Layers /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <span className="fw-semibold d-block mb-1"> ستاف</span>
                                            <h3 className="card-title mb-2">{staff ? commaNumber(staff.total) : 0} </h3>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <span className="fw-semibold d-block mb-1"> <icon.UserCheck /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">دەستپێک</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">سەرژمێری</li>
                </ol>
            </nav>
            <div className="row ">
                <div className="col-md-8">
                    <div className="row ">
                        {stats}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;